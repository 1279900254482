import React from "react";
import { Button, DatePicker, Select, message } from "antd";
import { PageContainer, PageHeader, PageContent } from "../Layout";
import ReconcilationTable from "../MyTable/reconcilationTable";
import { Filter } from "../Design";
import moment from "moment";
import styled from "styled-components";
import { fetchReportData, fetchReportFile } from "../../utils/reportRequest";
import { useSelector } from "react-redux";

export default ({ fileName, reportName }) => {
  const { accessToken, user } = useSelector((state) => state.auth);

  const ref = React.createRef();

  const [data, setData] = React.useState(null);

  const [filters, setFilters] = React.useState({
    // startDate: "2020-04-11",
    // endDate: "2026-04-11",
    startDate: "",
    endDate  : "",
  });

  const dateFilter = (e) => {
    setFilters({
      ...filters,
      startDate: e && moment(e[0]).startOf("days").format("YYYY-MM-DDTHH:mm"),
      endDate  : e && moment(e[1]).endOf("days").format("YYYY-MM-DDTHH:mm"),
    });
  };

  const onClear = () => {
    setFilters({
      startDate: "",
      endDate  : "",
    });
    setData(null);
  };

  React.useEffect(() => {
    if (filters.startDate != "" && filters.endDate != "") {
      getData();
    }
  }, [filters]);

  const getData = () => {
    if (ref.current != null) {
      ref.current.setLoading(true);
    }
    fetchReportData("/report/reconcilation", "POST", accessToken, {
      // request: options[idx].tablePageData.request,
      ...filters,
      // operatorCode: "OP10000006"
    })
      .then((data) => {
        if (data.responseCode && data.data) {
          setData(data.data);
        }
        else {
          setData([]);
        }
        if (ref.current != null) {
          ref.current.setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err ", err);
        if (ref.current != null) {
          ref.current.setLoading(false);
        }
      });
  };

  return (
    <PageContainer>
      <PageHeader title={reportName} key={reportName}/>
      <PageContent>
        <Filter
          extra={[
            <DatePicker.RangePicker
              value={[
                filters.startDate && moment(filters.startDate),
                filters.endDate && moment(filters.endDate),
              ]}
              onChange={dateFilter}
            />,
            <Button onClick={onClear}>Цэвэрлэх</Button>,
            <Button
              style={{ backgroundColor: "green", color: "white" }}
              onClick={() => {
                console.log("value", value);
                value
                  .filter((opCode) => opCode !== "all")
                    .forEach((opCode) => {
                      const selectedOption = options.find((option) => option.value === opCode);
                      if (selectedOption) {
                        fetchReportFile(
                          "/report/reconcilation?xlsx=true",
                          "POST",
                          accessToken,
                          {
                            ...filters,
                            operatorCode: opCode,
                          },
                          `${fileName}_${selectedOption.nameEn}`.toLowerCase()
                        );
                      }
                    });
              }}
            >
              Татах
            </Button>,
          ]}
        ></Filter>

        {data !== null && (
          <Table
            loading={true}
            filters={filters}
            ref={ref}
            data={data}
            paricipment={user?.operator?.name ?? ""}
            rowKey={(record) => record?.rowKey}
            getData={getData}
          />)
        }
      </PageContent>
    </PageContainer>
  );
};

const Table = styled(ReconcilationTable)`
  .ant-table-cell {
    padding: 0px;
    line-height: 15px;
  }
  .ant-table-thead .ant-table-cell {
    padding: 8px;
  }
  .ant-table-summary .ant-table-cell {
    padding: 8px;
  }
  .header-cell {
    text-align: left;
    background-color: #f5f5f5;
    font-weight: bold;
  }
  .subheader-cell {
    padding: 8px;
    padding-left: 40px;
    font-weight: bold;
  }
  .subvalue-cell {
    padding: 8px;
    font-weight: bold;
  }
  .cell-center {
    text-align: center;
  }
`;
