const formatNumber = (num) => {
    if (num === undefined || num === null || num === "") {
        return "";
    }
    const [integerPart, fractionalPart] = num.toString().split(".");
    const formattedIntegerPart = parseInt(integerPart, 10).toLocaleString("en-US");
    if (fractionalPart !== undefined) {
        return `${formattedIntegerPart}.${fractionalPart}`;
    }
    return `${formattedIntegerPart}.00`;
};
export default formatNumber;