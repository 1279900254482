import React from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { general, auth } from "./apis";
import { PrivateRoute, PublicRoute, NavBar, Header } from "./components";
import { Login, Notfound } from "./pages";
import { UnsuccessfulTransaction, SuccessfulTransaction, NetSettlement, ReconcilationReport} from "./pages/Report";
import { UserList, NewUser, EditUser, UserRole } from "./pages/User";
import { OperatorList, NewOperator, EditOperator } from "./pages/Operator";
import { EditFeeSetting, FeeSettingList } from "./pages/FeeSettings";
import Connection from "./pages/Connection";
import Message from "./pages/Message";
import Dashboard from "./pages/Dashboard";
import Transaction from "./pages/Transaction";
import Refund from "./pages/Refund";
import styled from "styled-components";
import FeeCalculation from "./pages/FeeCalculation";

const App = ({ isMobile }) => {
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.auth);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const asyncFn = async () => {
      try {
        await Promise.all([general.init()(dispatch)]);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    asyncFn();

    if (accessToken)
      auth.me()(dispatch);

  }, [dispatch, accessToken]);

  React.useEffect(() => {
    dispatch({
      type   : "layout.mobile",
      payload: isMobile
    });
  }, [isMobile]);

  if (loading) return <div>loading...</div>;

  return (
    <div>
      <Switch>
        <PublicRoute path="/login" component={Login} />
        <PrivateRoute path="/">
          <Header isMobile={isMobile} />
          <Container isMobile={isMobile}>
            <div className="content">
              <div className="navbar">
                <NavBar isMobile={isMobile} />
              </div>
              <div className="wrapper">
                <Switch>
                  <Route path="/" component={Dashboard} exact />

                  <Route path="/connection" component={Connection} exact />
                  <Route path="/message" component={Message} exact />
                  <Route path="/transaction/:type" component={Transaction} exact />
                  <Route path="/refund/:type" component={Refund} exact />

                  <Route path="/fee/settings" component={FeeSettingList} exact />
                  <Route path="/fee/settings/:id" component={EditFeeSetting} exact />
                  <Route path="/fee/calculation" component={FeeCalculation} exact />

                  <Route path="/report/unsuccessful_trans" component={UnsuccessfulTransaction} exact />
                  <Route path="/report/successful_trans" component={SuccessfulTransaction} exact />
                  <Route path="/report/net_settlement" component={NetSettlement} exact />
                  <Route path="/report/reconcilation" component={ReconcilationReport} exact />

                  {/* <Route path="/operator/list" component={OperatorList} exact />
                  <Route path="/operator/new" component={NewOperator} exact />
                  <Route path="/operator/edit/:id" component={EditOperator} exact />

                  <Route path="/user/list/:type" component={UserList} exact />
                  <Route path="/user/new/:type" component={NewUser} exact />
                  <Route path="/user/edit/:id" component={EditUser} exact /> */}

                  {/* <Route path="/user/role" component={UserRole} exact /> */}

                  <Route component={Notfound} />
                </Switch>
              </div>
            </div>
          </Container>
        </PrivateRoute>
      </Switch>
    </div>
  );
};

export default App;

const Container = styled.div`
  overflow: hidden; 
  .content { 
    position: relative;
    width: 100%; 
    background: #fff;
    .navbar {
      display: flex;
      width: ${(props) => props.isMobile ? 80 : 230}px;
      border-right: 1px solid #e8e8e8;
      height: calc(100vh - 60px);
      flex-direction: column;
      position: absolute;
      left: 0;
      top: 0;
      overflow: auto;
      overflow-x: hidden; 
      background: #ffffff;
      ::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }
      ::-webkit-scrollbar-button {
        width: 2px;
        height: 2px;
      }
      ::-webkit-scrollbar-thumb {
        background: #1890ff;
        border: 0px none #ffffff;
        border-radius: 50px;
      } 
    }
    .wrapper {
      position: relative; 
      padding-left: ${(props) => props.isMobile ? 80 : 230}px;
      height: calc(100vh - 60px);
      overflow: auto;
      overflow-x: hidden; 
    }
  }
`;
