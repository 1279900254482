import React from "react";
import { Tag, Button, DatePicker, InputNumber, Select } from "antd";
import { refund as refundApi } from "../../apis";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { fetchReportData, fetchReportFile } from "../../utils/reportRequest";
import { StickyHeaderCustomTable } from "../../components";
import { useParams } from "react-router-dom";
import { Filter } from "../../components/Design";
import styled from "styled-components";
import moment from "moment";
import { tugrug } from "../../utils";
import { useSelector } from "react-redux";

const TYPES = {
  card: "BANK_CARD",
  account: "BANK_ACCOUNT"
};

export default () => {
  const myTableRef = React.useRef();
  const params = useParams();
  const { accessToken } = useSelector((state) => state.auth);
  const [options, setOptions] = React.useState([]);

  const [filters, setFilters] = React.useState({
    type: TYPES[params.type],
    start_date: "",
    end_date: "",
    operator_code_credit: null,
    operator_code_debit: null,
    amount_min: null,
    amount_max: null
  });

  React.useEffect(() => {
    fetchReportData("/operator/list", "GET", accessToken).then((res) => {
      if (res.responseCode) {
        let listData = [];
        for (let index = 0; index < res.data.length; index++) {
          const selectedData = res.data[index];
          selectedData.label = selectedData.name;
          selectedData.value = selectedData.code;
          selectedData.tableRef = React.createRef();
          listData.push(selectedData);
        }
        setOptions(listData);
      }
    });
  }, []);

  const dateFilter = (e) => {
    setFilters({ ...filters, start_date: e && moment(e[0]).startOf("days").format("YYYY-MM-DDTHH:mm"), end_date: e && moment(e[1]).endOf("days").format("YYYY-MM-DDTHH:mm") });
  };
  const dateFilter2 = (e) => {
    setFilters({ ...filters, operator_code_credit: e });
  };
  const dateFilter3 = (e) => {
    setFilters({ ...filters, operator_code_debit: e });
  };

  const onChangeMin = (value) => {
    setFilters({ ...filters, amount_min: value });
  };

  const onChangeMax = (value) => {
    setFilters({ ...filters, amount_max: value });
  };

  const onClear = () => {
    setFilters({
      type: TYPES[params.type],
      start_date: undefined,
      end_date: undefined,
      operator_code_credit: null,
      operator_code_debit: null,
      amount_min: null,
      amount_max: null
    });
  };

  React.useEffect(() => {
    if (params.type)
      setFilters(state => ({
        ...state,
        type: TYPES[params.type]
      }));
  }, [params.type]);

  return (
    <PageContainer>
      <PageHeader title={`Буцаалтын жагсаалт (${params.type?.toUpperCase()})`} />
      <PageContent>
        <Filter
          extra={[<Button onClick={onClear}>Цэвэрлэх</Button>]}
          children={[
            <DatePicker.RangePicker value={[filters.start_date && moment(filters.start_date), filters.end_date && moment(filters.end_date)]} onChange={dateFilter} />,
            <Select
              // mode="multiple"
              style={{ width: "200px" }}
              options={options}
              placeholder="Харилцагч"
              maxTagCount="responsive"
              value={filters.operator_code_credit}
              onChange={(val) => {
                dateFilter2(val);
              }}
              optionRender={(option) => {
                return (
                  <Space>
                    <span role="img" aria-label={option.regUserId}>
                      {option.regUserId}
                    </span>
                  </Space>
                );
              }}
            />,
            <Select
              // mode="multiple"
              style={{ width: "200px" }}
              options={options}
              placeholder="Хүлээн авагч"
              maxTagCount="responsive"
              value={filters.operator_code_debit}
              onChange={(val) => {
                dateFilter3(val);
              }}
              optionRender={(option) => {
                return (
                  <Space>
                    <span role="img" aria-label={option.regUserId}>
                      {option.regUserId}
                    </span>
                  </Space>
                );
              }}
            />,
            <InputNumber placeholder="Бага дүн" min={1} defaultValue={null} value={filters.amount_min} onChange={onChangeMin} />,
            <InputNumber placeholder="Их дүн" min={1} defaultValue={null} value={filters.amount_max} onChange={onChangeMax} />,
          ]}>
        </Filter>

        <Table
          ref={myTableRef}
          filters={filters}
          loadData={refundApi.list}
          thead={() => (
            <thead className="ant-table-thead sticky">
              <tr>
                <th className="ant-table-cell" rowSpan={2}>№</th>
                <th className="ant-table-cell" colSpan={2}>Харилцагч</th>
                <th className="ant-table-cell" colSpan={2}>Төлбөр хүлээн авагч</th>
                <th className="ant-table-cell" colSpan={3}>Төлбөр</th>
                <th className="ant-table-cell" colSpan={2}>Төлсөн</th>
                <th className="ant-table-cell" rowSpan={2}>Төлөв</th>
                <th className="ant-table-cell" rowSpan={2}>Огноо</th>
              </tr>
              <tr>
                <th className="ant-table-cell">Оператор</th>
                <th className="ant-table-cell">Хэрэглэгч</th>
                <th className="ant-table-cell">Оператор</th>
                <th className="ant-table-cell">Хэрэглэгч</th>
                <th className="ant-table-cell">Хүлээн авах данс</th>
                <th className="ant-table-cell">Дүн</th>
                <th className="ant-table-cell">Гүйлгээний утга</th>
                <th className="ant-table-cell">Нөхцөл</th>
                <th className="ant-table-cell">Төлсөн данс</th>
              </tr>
            </thead>
          )} tbody={(row, index) => {
            return (
              <>
                <tbody key={index} className="ant-table-tbody">
                  <tr>
                    <td className="ant-table-cell" rowSpan={2}>{index + 1}</td>
                    <td className="ant-table-cell">
                      {row.credit.operator_name}
                    </td>
                    <td className="ant-table-cell">
                      {row.credit.customer_name}
                    </td>
                    <td className="ant-table-cell">
                      {row.debit.operator_name}
                    </td>
                    <td className="ant-table-cell">
                      {row.debit.customer_name}
                    </td>
                    <td className="ant-table-cell">
                      {row.beneficiary.bank_account_name}
                    </td>
                    <td className="ant-table-cell">
                      {tugrug(row.amount)}
                    </td>
                    <td className="ant-table-cell" rowSpan={2} style={{ minWidth: 200 }}>
                      {row.description}
                    </td>
                    <td className="ant-table-cell" rowSpan={2}>
                      {row.payment_method}
                    </td>
                    <td className="ant-table-cell">
                      {row.payment_method === "BANK_CARD" ? (
                        <div>{row.paid.cardholder_name}</div>
                      ) : (
                        <div>{row.paid.bank_account_name}</div>
                      )}
                    </td>
                    <td className="ant-table-cell" rowSpan={2}>
                      <Tag color="blue">{row.transaction_status}</Tag>
                    </td>
                    <td className="ant-table-cell" rowSpan={2} style={{ minWidth: 180 }}>{moment(row.created_at).format("YYYY-MM-DD HH:mm:ss")}</td>
                  </tr>
                  <tr>
                    <td className="ant-table-cell">
                      <Tag>{row.credit.operator_code}</Tag>
                    </td>
                    <td className="ant-table-cell">
                      <Tag>{row.credit.customer_code}</Tag>
                    </td>
                    <td className="ant-table-cell">
                      <Tag>{row.debit.operator_code}</Tag>
                    </td>
                    <td className="ant-table-cell">
                      <Tag>{row.debit.customer_code}</Tag>
                    </td>
                    <td className="ant-table-cell">
                      <Tag>{row.beneficiary.bank_code}: {row.beneficiary.bank_account_number}</Tag>
                    </td>
                    <td className="ant-table-cell">
                      <Tag color="red">{row.currency}</Tag>
                    </td>
                    <td className="ant-table-cell">
                      {row.payment_method === "BANK_CARD" ? (
                        <Tag>VISA: {row.paid.pan}</Tag>
                      ) : (
                        <Tag>{row.paid.bank_code}: {row.paid.bank_account_number}</Tag>
                      )}
                    </td>
                  </tr>
                </tbody>
                <tbody key={index} className="ant-table-tbody">
                  <tr>
                    <td className="ant-table-cell" colSpan={12}>
                      <h4>Шимтгэл</h4>
                      <table>
                        <thead className="ant-table-thead">
                          <tr>
                            <th className="ant-table-cell fee">Acquirer fee</th>
                            <th className="ant-table-cell fee">Issuer fee</th>
                            <th className="ant-table-cell fee">Bank of mongolia fee</th>
                            <th className="ant-table-cell fee">Epay fee</th>
                          </tr>
                        </thead>
                        <tbody key={index}>
                          <tr>
                            <td className="ant-table-cell fee">{tugrug(row.fee.acquirer_fee)}</td>
                            <td className="ant-table-cell fee">{tugrug(row.fee.issuer_fee)}</td>
                            <td className="ant-table-cell fee">{tugrug(row.fee.bom_fee)}</td>
                            <td className="ant-table-cell fee">{tugrug(row.fee.epay_fee)}</td>
                          </tr>
                        </tbody>
                      </table>
                      <h4>Буцаалтын мэдээлэл</h4>
                      <table>
                        <thead className="ant-table-thead">
                          <tr>
                            <th className="ant-table-cell fee">Буцаасан шалтгаан</th>
                            <th className="ant-table-cell fee">Буцаасан ажилтан</th>
                            <th className="ant-table-cell fee">Буцаасан огноо</th>
                          </tr>
                        </thead>
                        <tbody key={index}>
                          <tr>
                            <td className="ant-table-cell fee">{row.note || "-"}</td>
                            <td className="ant-table-cell fee">{row.refund_staff_name || "-"}</td>
                            <td className="ant-table-cell fee">{moment(row.refund_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </>
            );
          }} />
      </PageContent>
    </PageContainer>
  );
};

const Table = styled(StickyHeaderCustomTable)`
  .ant-table-cell {
    padding: 8px;
    line-height: 15px;
    &.fee {
      font-size: 14px;
      font-weight: normal;
    }
  }
`;