import React from "react";
import { Button, DatePicker, Select, message } from "antd";
import { PageContainer, PageHeader, PageContent } from "../Layout";
import netSettlementTable from "../MyTable/netSettlementTable";
import { Filter } from "../Design";
import moment from "moment";
import styled from "styled-components";
import { fetchReportData, fetchReportFile } from "../../utils/reportRequest";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

export default ({ fileName, reportName }) => {
  const { accessToken, user } = useSelector((state) => state.auth);

  // console.log("user bol user ", user);

  // const [options, setOptions] = React.useState([]);

  // const [value, setValue] = React.useState([]);

  const ref = React.createRef();

  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    // fetchReportData("/operator/list", "GET", accessToken).then((res) => {
    //   // var listData = [];
    //   if (res.responseCode) {
    //     for (let index = 0; index < res.data.length; index++) {
    //       const selectedData = res.data[index];
    //       selectedData.label = selectedData.name;
    //       selectedData.value = selectedData.code;
    //       //   selectedData.tableRef = React.createRef();
    //       //   selectedData.displayData = null;
    //       //   selectedData.tablePageData = {
    //       //     request: {
    //       //       pageNo: 1,
    //       //       perPage: 5,
    //       //       sort: "",
    //       //     },
    //       //   };
    //       //   selectedData.headerIndexes = [];
    //       //   selectedData.setHeaderIndexes = (value) => {
    //       //     // console.log("setHeaderIndexes", value);
    //       //     selectedData.headerIndexes = [
    //       //       ...(selectedData.headerIndexes, value),
    //       //     ];
    //       //   };
    //       //   selectedData.subheaderIndexes = [];
    //       //   selectedData.setSubheaderIndexes = (value) => {
    //       //     // console.log("setSubheaderIndexes", value);
    //       //     selectedData.subheaderIndexes = [
    //       //       ...selectedData.subheaderIndexes,
    //       //       value,
    //       //     ];
    //       //   };
    //       //   listData.push(selectedData);
    //     }
    //     // console.log("listData", listData);
    //     setOptions(res.data);
    //   }
    // });
  }, []);

  const [filters, setFilters] = React.useState({
    // startDate: "2020-04-11",
    // endDate: "2026-04-11",
    startDate: "",
    endDate  : "",
  });

  const dateFilter = (e) => {
    setFilters({
      ...filters,
      startDate: e && moment(e[0]).format("YYYY-MM-DDTHH:mm"),
      endDate  : e && moment(e[1]).format("YYYY-MM-DDTHH:mm"),
    });
  };

  React.useEffect(() => {
    if (filters.startDate != "" && filters.endDate != "") {
      getData();
    }
  }, [filters]);

  const onClear = () => {
    setFilters({
      startDate: "",
      endDate  : "",
    });
    setData(null);
  };

  const getData = () => {
    // const idx = options.findIndex((option) => option.value === optionValue);
    // if (options[idx].tableRef.current != null) {
    //   options[idx].tableRef.current.setLoading(true);
    //   options[idx].tablePageData.request.pageNo =
    //     options[idx].tableRef.current.getPage();
    //   options[idx].tablePageData.request.perPage =
    //     options[idx].tableRef.current.getLimit();
    // }
    if (ref.current != null) {
      ref.current.setLoading(true);
    }
    fetchReportData("/report/net/settlement", "POST", accessToken, {
      // request: options[idx].tablePageData.request,
      ...filters,
      // operatorCodes: options[idx].code,
    })
      .then((data) => {
        console.log("data ----> ", data);
        if (ref.current != null) {
          ref.current.setLoading(false);
        }
        if (data.responseCode && data.data) {
          setData(data.data);
          // options[idx].displayData = data.data;
          // setOptions([...options]);
        } else {
          setData([]);
        }
        // if (options[idx].tableRef.current != null) {
        //   options[idx].tableRef.current.setLoading(false);
        // }
      })
      .catch((err) => {
        console.log("err ", err);
        if (ref.current != null) {
          ref.current.setLoading(false);
        }
        setData([]);
        // if (options[idx].tableRef.current != null) {
        //   options[idx].tableRef.current.setLoading(false);
        // }
      });
  };

  return (
    <PageContainer>
      <PageHeader title={reportName} />
      <PageContent>
        <Filter
          extra={[
            <DatePicker.RangePicker
              // showTime={{
              //   hideDisabledOptions: true,
              //   defaultValue       : [
              //     dayjs("00:00:00", "HH:mm:ss"),
              //     dayjs("11:59:59", "HH:mm:ss"),
              //   ],
              // }}
              // format="YYYY-MM-DD HH:mm:ss"
              value={[
                filters.startDate && moment(filters.startDate),
                filters.endDate && moment(filters.endDate),
              ]}
              onChange={dateFilter}
            />,
            // <Select
            //   mode="multiple"
            //   style={{ width: "200px" }}
            //   options={options}
            //   placeholder="Select issuer..."
            //   maxTagCount="responsive"
            //   value={value}
            //   onChange={(val) => {
            //     if (
            //       filters.startDate === "" ||
            //       filters.startDate === null ||
            //       filters.endDate === "" ||
            //       filters.endDate === null
            //     ) {
            //       message.error("Эхлэх хугацаа, дуусах хугацааг сонгоно уу.");
            //     } else {
            //       // const lostOne = value.filter((item) => !val.includes(item));
            //       // if (lostOne.length > 0) {
            //       //   const lostOptionIndex = options.findIndex(
            //       //     (option) => option.value === lostOne[0]
            //       //   );
            //       //   options[lostOptionIndex].displayData = null;
            //       //   options[lostOptionIndex].tablePageData = {
            //       //     request: {
            //       //       pageNo: 1,
            //       //       perPage: 5,
            //       //       sort: "",
            //       //     },
            //       //   };
            //       //   options[lostOptionIndex].headerIndexes = [];
            //       //   options[lostOptionIndex].subheaderIndexes = [];
            //       // }

            //       // const addedOne = val.filter((item) => !value.includes(item));
            //       // if (addedOne.length > 0) {
            //       //   // const addedOptionIndex = options.findIndex(
            //       //   //   (option) => option.value === addedOne[0]
            //       //   // );
            //       //   getData(addedOne[0]);
            //       // }
            //       setValue(val);
            //       // if (val.length > 0) {
            //       //   getData(val);
            //       // }
            //     }
            //   }}
            //   optionRender={(option) => {
            //     // console.log("options", option);
            //     return (
            //       <Space>
            //         <span role="img" aria-label={option.regUserId}>
            //           {option.regUserId}
            //         </span>
            //       </Space>
            //     );
            //   }}
            // />,
            <Button onClick={onClear}>Цэвэрлэх</Button>,
            <Button
              style={{ backgroundColor: "green", color: "white" }}
              onClick={() => {
                fetchReportFile(
                  "/report/net/settlement?xlsx=true",
                  "POST",
                  accessToken,
                  {
                    ...filters,
                  },
                  `${fileName}_${user?.operator?.name ?? ""}`.toLowerCase()
                );
              }}
            >
              Татах
            </Button>,
          ]}
        ></Filter>

        {/* <React.Fragment key={}> */}
        {/* <h3>{selectedOption.label}</h3> */}
        {data !== null && (
          <Table
            loading={true}
            filters={filters}
            ref={ref}
            data={data}
            paricipment={user?.operator?.name ?? ""}
            // paricipment={selectedOption.name}
            // setHeaderIndexes={selectedOption.setHeaderIndexes}
            // setSubheaderIndexes={selectedOption.setSubheaderIndexes}
            // perPage={selectedOption.tablePageData.request.perPage}
            // headerIndexes={selectedOption.headerIndexes}
            // subheaderIndexes={selectedOption.subheaderIndexes}
            rowKey={(record) => record?.rowKey}
            getData={getData}
          />
        )}
        {/* <div style={{ height: "30px" }}></div> */}
        {/* </React.Fragment> */}

        {/* {value.map((el, index) => {
          const selectedOption = options.find((option) => option.value === el);
          return (
          );
        })} */}
      </PageContent>
    </PageContainer>
  );
};

const Table = styled(netSettlementTable)`
  .ant-table-cell {
    padding: 0px;
    line-height: 15px;
  }
  .ant-table-thead .ant-table-cell {
    padding: 8px;
  }
  .ant-table-summary .ant-table-cell {
    padding: 8px;
  }
  .header-cell {
    text-align: left;
    background-color: #f5f5f5;
    font-weight: bold;
  }
  .subheader-cell {
    padding: 8px;
    padding-left: 40px;
    font-weight: bold;
  }
  .subvalue-cell {
    padding: 8px;
    font-weight: bold;
  }
  .cell-center {
    text-align: center;
  }
`;
