import React from "react";
import { Button, DatePicker, Select, message } from "antd";
import { PageContainer, PageHeader, PageContent } from "../Layout";
import transactionTable from "../MyTable/transactionTable";
import { Filter } from "../Design";
import moment from "moment";
import styled from "styled-components";
import { fetchReportData, fetchReportFile } from "../../utils/reportRequest";
import { useSelector } from "react-redux";

export default ({ reportType, fileName, reportName, isSuccess }) => {
  const { accessToken, user } = useSelector((state) => state.auth);

  // const [options, setOptions] = React.useState([]);

  let initPage = 1;
  let initLimit = 5;

  const [headerIndexes, setHeaderIndexes] = React.useState([]);
  const [subheaderIndexes, setSubheaderIndexes] = React.useState([]);

  const tableRef = React.createRef();

  const [resData, setResData] = React.useState(null);

  const [filters, setFilters] = React.useState({
    // startDate: "2020-04-11",
    // endDate: "2026-04-11",
    startDate: "",
    endDate  : "",
  });

  const dateFilter = (e) => {
    setFilters({
      ...filters,
      startDate: e && moment(e[0]).startOf("days").format("YYYY-MM-DDTHH:mm"),
      endDate  : e && moment(e[1]).endOf("days").format("YYYY-MM-DDTHH:mm"),
    });
    setResData(null);
    if (tableRef.current != null) {
      tableRef.current.setPage(initPage);
      tableRef.current.setLimit(initLimit);
    }
  };

  React.useEffect(() => {
    getData(initPage, initLimit);
  }, [filters]);

  const onClear = () => {
    setFilters({
      startDate: "",
      endDate  : "",
    });
    setResData(null)
    // setValue([]);
  };

  const getData = (paramPage, paramLimit) => {
    let page = initPage;
    if (paramPage === undefined) {
      if (tableRef.current != null) {
        page = tableRef.current.getPage();
      }
    }

    let limit = initLimit;
    if (paramLimit === undefined) {
      if (tableRef.current != null) {
        limit = tableRef.current.getLimit();
      }
    }
    fetchReportData("/report/transaction", "POST", accessToken, {
      request: {
        pageNo : page,
        perPage: limit,
        sort   : "",
      },
      ...filters,
      transactionStatus: reportType,
      operatorCode: "OP10000007"
    })
      .then((data) => {
        if (data.responseCode) {
          setResData(data.data);
        }
        if (tableRef.current != null) {
          tableRef.current.setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err ", err);
        if (tableRef.current != null) {
          tableRef.current.setLoading(false);
        }
      });
  };

  return (
    <PageContainer>
      <PageHeader title={reportName} />
      <PageContent>
        <Filter
          extra={[
            <DatePicker.RangePicker
              value={[
                filters.startDate && moment(filters.startDate),
                filters.endDate && moment(filters.endDate),
              ]}
              onChange={dateFilter}
            />,
            <Button onClick={onClear}>Цэвэрлэх</Button>,
            <Button
              style={{ backgroundColor: "green", color: "white" }}
              onClick={() => {
                fetchReportFile(
                  "/report/transaction?xlsx=true",
                  "POST",
                  accessToken,
                  {
                    request: {
                      pageNo : tableRef.current.getPage(),
                      perPage: tableRef.current.getLimit(),
                      sort   : "",
                    },
                    ...filters,
                    transactionStatus: reportType,
                  },
                  `${fileName}_${user.operator.name}`.toLowerCase()
                );
              }}
            >
              Татах
            </Button>,
          ]}
        ></Filter>

        {resData !== null && (
          <Table
            ref={tableRef}
            loading={true}
            paricipment={user?.operator?.name ?? ""}
            filters={filters}
            data={resData}
            // columns={columns}
            setHeaderIndexes={setHeaderIndexes}
            setSubheaderIndexes={setSubheaderIndexes}
            perPage={initLimit}
            rowKey={(record) => record?.rowKey}
            getData={getData}
            isSuccess={isSuccess}
          />
        )}
      </PageContent>
    </PageContainer>
  );
};

const Table = styled(transactionTable)`
  .ant-table-cell {
    padding: 0px;
    line-height: 15px;
  }
  .ant-table-thead .ant-table-cell {
    padding: 8px;
  }
  .ant-table-summary .ant-table-cell {
    padding: 8px;
  }
  .header-cell {
    text-align: left;
    background-color: #f5f5f5;
    font-weight: bold;
  }
  .subheader-cell {
    padding: 8px;
    padding-left: 40px;
    font-weight: bold;
  }
  .subvalue-cell {
    padding: 8px;
    font-weight: bold;
  }
  .cell-center {
    text-align: center;
  }
`;
